@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./style/Colors.scss";
@import "./style/Fonts.scss";
@import "./style/Animation.scss";
@import "./style/Util.scss";
@import "./style/home.scss";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  transition: ease-in 150ms;
}

a {
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
body{
  overflow-x: hidden;
}

* {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

