@keyframes scrollCompany1{
    from{
        transform: translateX(100%);
    }
    to{
        transform: translateX(0%);
    }
}

@keyframes scrollCompany2{
    from{
        transform: translateX(-100%);
    }
    to{
        transform: translateX(-200%);
    }
}