@media only screen and (min-width: 640px) {
    .blur{
        filter: blur(0px);
    }
    
    .stop-scroll{
        overflow: hidden;
        max-height: 100vh;
    }
}
@media only screen and (max-width: 639px) {
    .blur{
        filter: blur(0px);
    }
    
    // .stop-scroll{
    //     overflow: hidden;
    //     max-height: 100vh;
    // }
}