@media only screen and (min-width: 1020px) {
    .gaurav-card-width{
        width: 20vw!important;
      }
    .garima-card-width{
        width: 20.5vw!important;
      }
  }

  .modal-bg-shadow{
    grid-template-columns: 40% 60%;
    box-shadow: 0px 4px 36px 8px rgba(33, 206, 153, 0.2);
    border-radius: 5px;
    border: 1px solid #0052CC;
  }

  .modal-bg-backdrop{
    background: rgba(248, 248, 248, 0.75);
    backdrop-filter: blur(8px);
  }